export const siteTitle = '부동산 필수 앱 다방';
export const m2 = 3.3058;

export const isDev = process.env.NODE_ENV === 'development';
export const isTest = process.env.REACT_APP_IS_TEST === 'true';

export const geojsonUrl = process.env.REACT_APP_GEOJSON_URL ?? 'https://test-dabang-main.dabangapp.com';

export const webviewUrl = isTest ? 'https://test-dabang-webview.dabangapp.com' : 'https://dabang-webview.dabangapp.com';
export const proUrl = isTest ? 'https://test-dabang-pro-v2.dabangapp.com' : 'https://pro.dabangapp.com';
export const hubUrl = isTest ? 'https://test-dabang-owner-v3.dabangapp.com' : 'https://hub.dabangapp.com';

export const signUrl = isTest ? 'https://test-dabang-sign.dabangapp.com' : 'https://sign.dabangapp.com';

const keys = process.env.REACT_APP_NAVER_MAP?.split(',') as string[];
const idx = Math.floor(Math.random() * keys.length);
export const naverMapKey = keys[idx];

export const imgRotate =  window.location.protocol + '//upload-images.dabangapp.com/rotate';
export const roomUpBucket = isTest ? 'dabang-dev-temp-image' : 'dabang-prod-temp-image';
export const fakeRoomBucket = isTest ? 'dabang-dev-fake-room-image' : 'dabang-prod-fake-room-image';
export const fakeRewardBucket = isTest ? 'dabang-dev-fake-reward-room-image' : 'dabang-prod-fake-reward-room-image';

export const getImageServerUploadUrl = (type: 'upload' | 'rotate') => {
  const prefixUrl = window.location.protocol + `${isTest ? '//test-dabang-upload-image.dabangapp.com' : '//upload-images.dabangapp.com'}`;
  return `${prefixUrl}/${type}`;
};

export const tempImgUrl = `https://s3-ap-northeast-1.amazonaws.com/${roomUpBucket}/`;
export const fakeRoomImageUrl = `https://s3-ap-northeast-2.amazonaws.com/${fakeRoomBucket}/`;
export const fakeRewardImageUrl = `https://s3-ap-northeast-2.amazonaws.com/${fakeRewardBucket}/`;

// naver blog
export const naverBlogUrl = 'https://blog.naver.com/station3inc';
// naver post
export const naverPostUrl = 'https://post.naver.com/station3inc';
// facebook
export const facebookUrl = 'https://www.facebook.com/dabangapp';
// instagram
export const instagramUrl = 'https://www.instagram.com/dabang_app';
// youtube
export const youtubeUrl = 'https://www.youtube.com/user/DabangApp';
// kakao pixel
export const kakaoPixelTrackId = '5718144240774234379';
